@import "react-toastify/dist/ReactToastify.css";

:root {
  /* the colors are taken from shared/colors.ts */
  --_main-color: #002c5f;
  --_main-white: #f5f5f5;
  --_green-light-color: #66cc66;
  --_red-color: #ff1a1a;

  --toastify-color-light: var(--_main-white);
  --toastify-color-success: var(--_green-light-color);
  --toastify-color-info: var(--_main-color);
  --toastify-color-error: var(--_red-color);
  --toastify-spinner-color: var(--_main-color);
  --toastify-text-color-light: var(--_main-color);

  --toastify-font-family: HyundaiFontMediumText;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
