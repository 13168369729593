body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "HyundaiFontHead",
    "HyundaiFontMediumText";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "HyundaiFontMediumText", monospace;
}

@font-face {
  font-family: "HyundaiFontHead";
  src:
    local("HyundaiFontHead"),
    url(shared/fonts/HyundaiSansHead-Medium.otf) format("opentype");
}

@font-face {
  font-family: "HyundaiFontMediumText";
  src:
    local("HyundaiFontMediumText"),
    url(shared/fonts/HyundaiSansText-Medium.otf) format("opentype");
}
